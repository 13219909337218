import trasladoDeDominioImg from "../assets/procedures/1.jpg";
import actaConstitutivaImg from "../assets/procedures/2.jpg";
import donaciónYAdjudicacionImg from "../assets/procedures/3.jpg";
import testamentoImg from "../assets/procedures/4.jpg";
import bannerSucesion from "../assets/procedures/banner_sucesion.jpg";
import actaConstitutivaFile from "../assets/procedures/files/formatoDeConstitucionDeSociedad.docx";
import testamentoFile from "../assets/procedures/files/formularioTestamento.pdf";

const caseStudies = (t) => [
  {
    banner: trasladoDeDominioImg,
    title: "Traslado de Dominio",
    resumen:
      "Es el contrato por el cual el vendedor entrega la posesión y derechos de un bien inmueble a otra persona y este se compromete a pagar por el. Es un contrato consensual, bilateral, oneroso, conmutativo y sirve para transmitir el dominio. Es necesario que exista objeto y precio.",
    description: `<strong>Enajenante o Vendedor</strong>
      1.	Original de Escritura antecedente 
      2.	Original y Copia de INE o IFE <strong>vigente</strong>
      3.	Original de acta de nacimiento
      4.	Copia de Acta de matrimonio, en caso de ser soltero deberá presentar <strong>CONSTANCIA DE NO MATRIMONIO.</strong>
      5.	CURP
      6.	Constancia de Situación Fiscal
      7.	Original y Copia simple de comprobante de domicilio, con una vigencia no mayor a tres meses, <strong>el Recibo de Agua no es válido como comprobante de domicilio.</strong>
      8.	Recibo predial pagado al año en curso
      9.	Certificado de No Adeudo predial o por sus siglas CNA
      10.	Original del recibo de agua pagado
      11.	Original del Certificado de No Adeudo de agua del mes anterior a la fecha de firma de escritura
      
      <strong><i>Si desea que el CNA del Predial y/o del Agua sean tramitados por la Notaria, favor de mencionar al abogado para hacer las gestiones correspondientes</i></strong>

      <strong>Para exentar ISR es necesario presentar alguno de los siguientes documentos con una vigencia no mayores a 3 meses y con el mismo domicilio de la casa a vender</strong>
      1.	Original de recibos de cuenta bancarios
      2.	Original de recibos de luz con cadena CFDI SAT
      3.	INE con domicilio del inmueble a vender

      <strong>Adquirente o comprador</strong>
      1.	Original y Copia de INE o IFE <strong>vigente</strong>
      2.	Original de acta de nacimiento
      3.	Copia de Acta de matrimonio, en caso de ser soltero deberá presentar <strong>CONSTANCIA DE NO MATRIMONIO.</strong>
      4.	CURP
      5.	Constancia de Situación Fiscal
      6.	Original y Copia simple de comprobante de domicilio, con una vigencia no mayor a tres meses, <strong>el Recibo de Agua no es válido como comprobante de domicilio.</strong>

      <strong>--- IMPORTANTE ---</strong>
      <strong><i>Si el predio está en Condominio, es necesario añadir las Cuotas Condominales.</i></strong>  
      <strong><i>Si el INE o IFE de alguno de los interesados no estuviera vigente o no lo tuviera, se puede presentar este requisito con 2 identificaciones en Original y vigentes como las que se mencionan a continuación:</i></strong>
      <strong><i>• Pasaporte</i></strong>
      <strong><i>• Licencia De Conducir</i></strong>
      <strong><i>• Cedula Profesional</i></strong>
      <strong><i>• INSEN o INAPAM</i></strong>`,
  },
  {
    banner: actaConstitutivaImg,
    title: "Acta Constitutiva",
    resumen:
      "Unión de personas físicas o jurídicas, para crear un nuevo ente jurídico, con personalidad y patrimonio propio, distinto al de los demás. Generación de una nueva empresa.",
    description: `<strong>Documentos por cada Socio</strong>
    1.	Original y Copia de INE o IFE <strong>vigente.</strong>
    2.	Original y copia de acta de nacimiento
    3.	CURP
    4.	Constancia de Situación Fiscal
    5.	Original y Copia simple de comprobante de domicilio, con una vigencia no mayor a tres meses, <strong>el Recibo de Agua no es válido como comprobante de domicilio.</strong>
    6.	Formulario de Constitución de Sociedad debidamente elaborado.
    7.	Original de <strong>CONSTANCIA DE NO MATRIMONIO</strong>, si alguno de los socios está casado(a), deberá presentar <strong>Original de ACTA DE MATRIMONIO</strong> y los mismos documentos anteriormente mencionados de su cónyuge.
    \n<strong>Documentos para el Comisario</strong>
    1. Original y Copia de INE o IFE <strong>vigente.</strong>
    2.	Constancia de Situación Fiscal   

    <strong>--- IMPORTANTE ---</strong>
    <strong><i>Si el INE o IFE de alguno de los interesados no estuviera vigente o no lo tuviera, se puede presentar este requisito con 2 identificaciones en Original y vigentes como las que se mencionan a continuación:</i></strong>
    <strong><i>• Pasaporte</i></strong>
    <strong><i>• Licencia De Conducir</i></strong>
    <strong><i>• Cedula Profesional</i></strong>
    <strong><i>• INSEN o INAPAM</i></strong>`,
    file: {
      title: "formularioConstitución.docx",
      file: actaConstitutivaFile,
    },
  },
  {
    banner: donaciónYAdjudicacionImg,
    title: "Donación o Adjudicación",
    resumen:
      "Es el acto jurídico en virtud de cual el donante dispone de un inmueble de su patrimonio y la otorga al donatario gratuitamente. Requiere la liberalidad del donante y la aceptación del donatario.",
    description: `<strong>Donante(s)</strong>
    1.	Original de Escritura antecedente 
    2.	Original y Copia de INE o IFE <strong>vigente</strong>
    3.	Original de acta de nacimiento
    4.	Copia de Acta de matrimonio, en caso de ser soltero deberá presentar <strong>CONSTANCIA DE NO MATRIMONIO.</strong>
    5.	CURP
    6.	Constancia de Situación Fiscal
    7.	Original y Copia simple de comprobante de domicilio, con una vigencia no mayor a tres meses, <strong>el Recibo de Agua no es válido como comprobante de domicilio.</strong>
    8.	Recibo predial pagado al año en curso
    9.	Certificado de No Adeudo predial o por sus siglas CNA
    10.	Original del recibo de agua pagado
    11.	Original del Certificado de No Adeudo de agua del mes anterior a la fecha de firma de escritura
    
    <strong><i>Si desea que el CNA del Predial y/o del Agua sean tramitados por la Notaria, favor de mencionar al abogado para hacer las gestiones correspondientes</i></strong>

    <strong>Donatario(s)</strong>
    1.	Original y Copia de INE o IFE <strong>vigente</strong>
    2.	Original de acta de nacimiento
    3.	Copia de Acta de matrimonio, en caso de ser soltero deberá presentar <strong>CONSTANCIA DE NO MATRIMONIO.</strong>
    4.	CURP
    5.	Constancia de Situación Fiscal
    6.	Original y Copia simple de comprobante de domicilio, con una vigencia no mayor a tres meses, <strong>el Recibo de Agua no es válido como comprobante de domicilio.</strong>

    <strong>--- IMPORTANTE ---</strong>
    <strong><i>Si el predio está en Condominio, es necesario añadir las Cuotas Condominales.</i></strong>  
    <strong><i>Si el INE o IFE de alguno de los interesados no estuviera vigente o no lo tuviera, se puede presentar este requisito con 2 identificaciones en Original y vigentes como las que se mencionan a continuación:</i></strong>
    <strong><i>• Pasaporte</i></strong>
    <strong><i>• Licencia De Conducir</i></strong>
    <strong><i>• Cedula Profesional</i></strong>
    <strong><i>• INSEN o INAPAM</i></strong>`,
  },
  {
    banner: bannerSucesion,
    title: "Sucesión ante Notario",
    resumen:
      "Proceso por el cual un notario lleva a cabo la validación de un testamento y formaliza la transmisión de los bienes del testador a favor de sus herederos o legatarios, cumpliendo así su última voluntad y evitando procesos judiciales.",
    description: `<strong>Del Finado</strong>
    1. Original del Testamento
    2. Original de Acta de Defunción
    3. Original de acta de Nacimiento
    4. Original de Acta de Matrimonio, si fue soltero, deberá presentar en Original de <strong>CONSTANCIA DE NO MATRIMONIO</strong>
    \n<strong>De los Herederos</strong>
    1. Original y Copia de INE o IFE vigente.
    2. CURP
    3. Constancia de Situación Fiscal
    4. Original y Copia simple de comprobante de domicilio, con una vigencia no mayor a tres meses, <strong>el Recibo de Agua no es válido como comprobante de domicilio.</strong>
    5. Original de Acta de Nacimiento
    6. Original de Acta de matrimonio, si es soltero, deberá presentar Original de <strong>CONSTANCIA DE NO MATRIMONIO</strong>
    \n<strong>De cada inmueble</strong>
    1. Copia simple de escrituras con boleta de registro
    2. Recibo predial pagado al año en curso
    3. Original del recibo de agua pagado
    \n<strong>--- IMPORTANTE ---</strong>
    <strong><i>Si el INE o IFE de alguno de los interesados no estuviera vigente o no lo tuviera, se puede presentar este requisito con 2 identificaciones en Original y vigentes como las que se mencionan a continuación:</i></strong>
    <strong><i>• Pasaporte</i></strong>
    <strong><i>• Licencia De Conducir</i></strong>
    <strong><i>• Cedula Profesional</i></strong>
    <strong><i>• INSEN o INAPAM</i></strong>`,
  },
  {
    banner: testamentoImg,
    title: "Testamento",
    resumen:
      "La declaración voluntaria de una persona que expresa lo que quiere que suceda con sus bienes después de su fallecimiento; conlleva distintos requisitos y se decide quienes serán sus sucesores.",
    description: `<strong>Testador</strong>
    1. Original y Copia de INE o IFE <strong>vigente</strong>
    2. Original de acta de nacimiento
    3. CURP
    4. Original y Copia simple de comprobante de domicilio, con una vigencia no mayor a tres meses, <strong>el Recibo de Agua no es válido como comprobante de domicilio.</strong>
    5. Original de Acta de matrimonio, si es soltero, deberá presentar Original de <strong>CONSTANCIA DE NO MATRIMONIO</strong>
    6. Formato de Testamento debidamente elaborado
    \n<strong>--- IMPORTANTE ---</strong>
    <strong>En caso de que por cualquier causa el interesado no pueda leer o escribir, deberá asistir con 2 testigos que no sean familiares, herederos o legatarios. Ellos deberán presentar lo siguiente:</strong>
    •	Original y Copia de INE o IFE <strong>vigente</strong>
    •	CURP
    •	Original y Copia simple de comprobante de domicilio, con una vigencia no mayor a tres meses, <strong>el Recibo de Agua no es válido como comprobante de domicilio.</strong>
    \n<strong>Si el INE o IFE no estuviera vigente o no lo tuviera tanto el testador como los testigos, puede presentar este requisito con 2 identificaciones en Original y vigentes como las que se mencionan a continuación:</strong>
    <strong>•	Pasaporte</strong>
    <strong>•	Licencia De Conducir</strong>
    <strong>•	Cedula Profesional</strong>
    <strong>•	INSEN o INAPAM</strong>`,
    file: {
      title: "testamento.pdf",
      file: testamentoFile,
    },
  },
];

export default caseStudies;
