const menuListData = (t) => {
    return [{
            name: t('landing.navbar.menu.home'),
            href: "/",
            className: "text-white text-sm 2xl:text-lg cursor-pointer",
            offset: -100,
        },
        {
            name: t('landing.navbar.menu.resume'),
            href: "resume",
            className: "text-white text-sm 2xl:text-lg cursor-pointer",
            offset: -55,
        },
        {
            name: t('landing.navbar.menu.whoWeAre'),
            href: "whoWeAre",
            className: "text-white text-sm 2xl:text-lg cursor-pointer",
            offset: -50,
        },
        {
            name: t('landing.navbar.menu.ourProcedures'),
            href: "ourProcedures",
            className: "text-white text-sm 2xl:text-lg cursor-pointer",
            offset: -50,
        },
        {
            name: t('landing.navbar.menu.location'),
            href: "location",
            className: "text-white text-sm 2xl:text-lg cursor-pointer",
            offset: -50,
        },
        {
            name: t('landing.navbar.menu.usefulSites'),
            href: "usefulSites",
            className: "text-white text-sm 2xl:text-lg cursor-pointer",
            offset: -50,
        },
    ]
};

const sideMenuListData = (t) => {
    return [{
            name: t('landing.navbar.menu.home'),
            href: "/",
            className: "p-3 text-sm cursor-pointer",
            offset: -100,
        },
        {
            name: t('landing.navbar.menu.resume'),
            href: "resume",
            className: "p-3 text-sm cursor-pointer",
            offset: -50,
        },
        {
            name: t('landing.navbar.menu.whoWeAre'),
            href: "whoWeAre",
            className: "p-3 text-sm cursor-pointer",
            offset: -50,
        },
        {
            name: t('landing.navbar.menu.ourProcedures'),
            href: "ourProcedures",
            className: "p-3 text-sm cursor-pointer",
            offset: -50,
        },
        {
            name: t('landing.navbar.menu.location'),
            href: "location",
            className: "p-3 text-sm cursor-pointer",
            offset: -50,
        },
        {
            name: t('landing.navbar.menu.usefulSites'),
            href: "usefulSites",
            className: "p-3 text-sm cursor-pointer",
            offset: -50,
        },
    ]
};

export {
    menuListData,
    sideMenuListData
};