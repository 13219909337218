const shareWhatsApp = (text, title) => {
    const textWithTitle = `
<strong>Requisitos - ${title}</strong>\n
${text}`;
    const bold = textWithTitle.replace(/<\/?strong>/g, '*');
    const newText = bold.replace(/<\/?i>/g, '_');
    const encodedText = encodeURIComponent(newText);
    const whatsappUrl = `https://wa.me/?text=${encodedText}`;
    window.open(whatsappUrl, "_blank");
};

export {
    shareWhatsApp
};