import React, { useState } from "react";
import { motion } from "framer-motion";
import {
  IoArrowForwardOutline,
  IoLogoWhatsapp,
  IoDocumentAttachOutline,
} from "react-icons/io5";
import { shareWhatsApp } from "../utils/share";

const CardStudies = ({ item, t }) => {
  const [isOpenCardDescription, setIsOpenCardDescription] = useState(false);

  const handleCardDescription = () => {
    setIsOpenCardDescription(!isOpenCardDescription);
  };

  const saveFile = (file, title) => {
    fetch(file).then((response) => {
      response.blob().then((blob) => {
        let url = window.URL.createObjectURL(blob);
        let a = document.createElement("a");
        a.href = url;
        a.download = title;
        a.click();
      });
    });
  };

  return (
    <div className="group card rounded-md b-white border transition-shadow ease-in-out delay-100 hover:shadow-2xl cursor-pointer relative overflow-hidden">
      <div
        className="header-image flex justify-center items-center"
        style={{
          width: "100%",
          height: 120,
          overflow: "hidden",
        }}
      >
        <img
          className="w-full transition-all ease-in-out delay-50 duration-500 group-hover:scale-125"
          alt="banner"
          src={item.banner}
          style={{ objectFit: "cover" }}
        />
      </div>
      <div className="body-card p-5 flex flex-col items-center h-[22rem]">
        <h1 className="text-lg font-bold mt-2">{item.title}</h1>
        <p className="text-sm font-normal text-gray-700 text-center text-justify">
          {item.resumen}
        </p>
        <div className="actions absolute bottom-0 mb-16 flex gap-2">
          <div
            className="w-12 h-12 mt-2 flex justify-center items-center bg-primary rounded-full"
            onClick={() => shareWhatsApp(item.description, item.title)}
          >
            <IoLogoWhatsapp className="text-2xl text-white" />
          </div>
          {item?.file && (
            <div
              className="w-12 h-12 mt-2 flex justify-center items-center bg-[#B04759] rounded-full"
              onClick={() => saveFile(item.file.file, item.file.title)}
            >
              <IoDocumentAttachOutline className="text-2xl text-white" />
            </div>
          )}
        </div>
      </div>
      <div
        className="footer-card w-full absolute p-2 pt-4 pb-4 transition-all ease-in-out delay-50 duration-500 group-hover:bg-primary text-white flex justify-center border bottom-0"
        onClick={handleCardDescription}
      >
        <IoArrowForwardOutline className="transition-all ease-in-out delay-50 duration-500 group-hover:rotate-90 text-gray-300 group-hover:text-white" />
      </div>
      <motion.div
        onClick={handleCardDescription}
        className={`h-full w-full top-0 z-5 bg-white absolute p-8 overflow-y-auto whitespace-pre-line`}
        initial={{ y: "0" }}
        animate={{ x: "0.3px", y: isOpenCardDescription ? "0" : "100%" }}
        transition={{
          type: "spring",
          duration: 1,
          repeatType: "mirror",
        }}
      >
        <h1 className="text-lg font-bold mt-2">
          {t("landing.ourProcedures.requirements")}
        </h1>
        <p
          className="text-sm font-normal text-gray-700"
          dangerouslySetInnerHTML={{ __html: item.description }}
        ></p>
      </motion.div>
    </div>
  );
};

export default CardStudies;
