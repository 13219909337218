import { useState, useEffect } from "react";
import { motion } from "framer-motion";
import { Link as ScrollLink, Events } from "react-scroll";
import { menuListData, sideMenuListData } from "./data/menus.listdata";
import caseStudies from "./data/caseStudies.listdata";
import CardStudies from "./components/CardStudies";
import { usefulSites } from "./data/usefulSites.listdata";
import { mexico, USA, banner, logo, licenciado } from "./utils/images";
import {
  Button,
  Dialog,
  DialogHeader,
  DialogBody,
  DialogFooter,
} from "@material-tailwind/react";
import {
  IoLogoWhatsapp,
  IoCall,
  IoMenuOutline,
  IoArrowForwardOutline,
  IoAlertCircle,
} from "react-icons/io5";
import { FaHandsHelping, FaEye } from "react-icons/fa";
import styles from "./styles/landing.module.css";
import { useTranslation } from "react-i18next";
import Avatar from "./components/Avatar";

function App() {
  const [t, i18n] = useTranslation("translation");
  const [isOpenMenu, setIsOpenMenu] = useState(false);
  const [onsCroll, setOnsCroll] = useState(false);
  const [seeMoreWhoWeAre, setSeeMoreWhoWeAre] = useState(false);
  const [openImportantDialog, setOpenImportantDialog] = useState(false);

  const handleOpenMenu = () => {
    setIsOpenMenu(true);
  };

  const handleCloseMenu = () => {
    setIsOpenMenu(false);
  };

  const handleOnScroll = () => {
    setOnsCroll(window.scrollY > 90);
  };

  if (typeof window !== "undefined") {
    window.addEventListener("scroll", handleOnScroll);
  }

  Events.scrollEvent.register("begin", () => {
    handleCloseMenu();
  });

  const handleImportantDialog = (open) => {
    localStorage.setItem("importantVideoViewed", true);
    setOpenImportantDialog(open);
  };

  useEffect(() => {
    setTimeout(() => {
      if (!localStorage.getItem("importantVideoViewed")) {
        handleImportantDialog(true);
      }
    }, 8000);
  }, []);

  return (
    <div className="App" onScroll={handleOnScroll}>
      <div
        className={`${
          styles.nav
        } lg:mt-0 xl:mt-0 z-50 transition-all ease-in-out delay-50 duration-500 h-16 lg:h-auto xl:h-auto pt-2 pb-2 ${
          onsCroll ? "bg-primary" : ""
        }`}
      >
        <div className="ml-5 flex items-center">
          <a href="tel:3331232322" className="flex items-center">
            <div className="box bg-primary w-10 h-10 flex items-center justify-center rounded-md">
              <IoCall className="text-white" />
            </div>
          </a>
          <p className="text-xs text-white ml-2 font-light hidden lg:inline-block xl:inline-block">
            {t("landing.navbar.contacto")}{" "}
            <div className="flex gap-1">
              <a href="tel:3331232322" className="flex items-center">
                <span className="block font-bold">33-3123-2322</span>
              </a>
              -
              <a href="tel:3338352051" className="flex items-center">
                <span className="block font-bold">33-3835-2051</span>
              </a>
            </div>
          </p>
        </div>
        <div className="menu flex gap-6 translate-x-[-2.5rem] hidden lg:flex xl:flex">
          {menuListData(t).map((item) => (
            <ScrollLink
              key={item.href}
              className={item.className}
              to={item.href}
              spy={true}
              smooth={true}
              offset={item.offset}
              duration={500}
            >
              {item.name}
            </ScrollLink>
          ))}
        </div>
        <div className="social flex items-center gap-3 mr-5">
          <div
            className="group relative flex justify-center"
            onClick={() => handleImportantDialog(true)}
          >
            <IoAlertCircle className="text-yellow-300 text-2xl cursor-pointer" />
            <span className="absolute top-10 scale-0 transition-all rounded bg-gray-800 p-2 text-xs text-white group-hover:scale-100 whitespace-nowrap">
              ⚠️ {t("landing.navbar.importantTitle")}
            </span>
          </div>
          {i18n.language === "en" ? (
            <img
              alt="banner"
              src={mexico}
              width={20}
              className="cursor-pointer"
              onClick={() => i18n.changeLanguage("es")}
            />
          ) : (
            <img
              alt="banner"
              src={USA}
              width={20}
              className="cursor-pointer"
              onClick={() => i18n.changeLanguage("en")}
            />
          )}
          <a
            href="https://api.whatsapp.com/send?phone=5213327959935&text=%C2%A1Hola!%20Me%20gustar%C3%ADa%20recibir%20informes"
            target="_blank"
            rel="noopener noreferrer"
          >
            <IoLogoWhatsapp className="text-white text-2xl cursor-pointer" />
          </a>
        </div>
      </div>
      <div id="/" className={styles.header}>
        <div className={styles.background}>
          <div className="w-4/6 xs:w-4/6 sm:w-2/4 lg:w-1/3 xl:w-1/4 m-5 mt-10">
            <img src={logo} loading="lazy" alt="logo" />
          </div>
          <div className="flex gap-1 flex-wrap justify-center">
            <a
              href="https://maps.app.goo.gl/byv3KG3KrmquJqz27"
              target="_blank"
              className="bg-primary pt-4 pb-4 pl-10 pr-10 mt-3 text-sm font-bold rounded-md"
              rel="noopener noreferrer"
            >
              {t("landing.header.location")}
            </a>
            <a
              href="https://api.whatsapp.com/send?phone=5213327959935&text=%C2%A1Hola!%20Me%20gustar%C3%ADa%20recibir%20informes"
              target="_blank"
              className="pt-4 pb-4 pl-10 pr-10 mt-3 text-sm font-bold flex items-center gap-1"
              rel="noopener noreferrer"
            >
              <IoLogoWhatsapp size={15} /> WhatsApp
            </a>
          </div>
        </div>
        <img className={styles.banner} alt="banner" src={banner} />
      </div>
      <section id="resume" className="p-5 flex flex-col items-center">
        <h1 className="text-center text-secondary font-bold">
          {t("landing.resume.notarioLabel")}
        </h1>
        <h1 className="text-3xl font-bold text-center text-primary">
          {t("landing.resume.resumeTitle")}
        </h1>
        <div className="container grid md:grid-flow-col lg:grid-flow-col xl:grid-flow-col gap-5 mt-5 pl-2 pr-2 md:pl-14 md:pr-14 pb-12 pt-4">
          <div className="left">
            <img
              className="w-100 xs:w-72 sm:w-72 md:w-72 lg:w-72 xl:w-72 2xl:w-72 h-auto object-cover object-center shadow-md rounded-xl"
              src={licenciado}
              style={{ objectFit: "cover" }}
              loading="lazy"
              alt="Licenciado"
            />
          </div>
          <div className="right text-sm text-justify whitespace-pre-line">
            <span className="text-xl block text-primary font-semibold">
              {t("landing.resume.notario")}
            </span>
            {t("landing.resume.resume")}
          </div>
        </div>
      </section>
      <section id="whoWeAre" className="relative border flex justify-center">
        <div className="w-full h-full bg-backgroundImages flex justify-center">
          <div className="container flex flex-col justify-center items-center p-5 lg:p-16 xl:p-16">
            <div className="call-box w-14 h-14 bg-secondary flex justify-center items-center absolute top-0">
              <FaHandsHelping className="text-white text-3xl" />
            </div>
            <h1 className="text-white mt-12 text-2xl font-bold">
              {t("landing.whoWeAre.title")}
            </h1>
            <div className="right text-md text-justify whitespace-pre-line text-white font-semibold mt-2">
              {t("landing.whoWeAre.resume")}
              <br />
              <span className={seeMoreWhoWeAre ? "" : "hidden"}>
                {t("landing.whoWeAre.resumeHidden")}
              </span>
            </div>
            <button
              className="bg-primary pt-4 pb-4 pl-10 pr-10 mt-3 text-sm font-bold flex items-center gap-1 rounded-md text-white"
              onClick={() => setSeeMoreWhoWeAre(!seeMoreWhoWeAre)}
            >
              <FaEye className="text-white text-xl" />
              {seeMoreWhoWeAre
                ? t("landing.whoWeAre.seeLess")
                : t("landing.whoWeAre.seeMore")}
            </button>
          </div>
        </div>
      </section>
      <section
        id="ourProcedures"
        className="ourProcedures p-5 flex flex-col items-center"
      >
        <h1 className="text-3xl font-bold text-center text-primary">
          {t("landing.ourProcedures.legalServices")}
        </h1>
        <div className="container grid 2xl:grid-cols-4 xl:grid-cols-3 lg:grid-cols-3 md:grid-cols-2 sm:grid-cols-1 gap-5 mt-5 pt-5 pl-0 pr-0 md:pl-10 md:pr-10 lg:pl-20 lg:pr-20 xl:pl-20 xl:pr-20">
          {caseStudies(t).map((item) => (
            <CardStudies key={item.title} item={item} t={t} />
          ))}
        </div>
      </section>
      <section id="location" className="w-100" style={{ height: 500 }}>
        <iframe
          title="location"
          src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3732.97753111293!2d-103.40239802640139!3d20.67049309999056!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x8428aff7a2793067%3A0xba288ed806f6d4f8!2sNotaria%2066%20Guadalajara!5e0!3m2!1ses!2smx!4v1687123666202!5m2!1ses!2smx"
          allowFullScreen
          style={{ width: "100%", height: "100%", border: 0 }}
          loading="lazy"
        ></iframe>
      </section>
      <section
        id="usefulSites"
        className="w-100 h-auto p-10 flex flex-col items-center"
      >
        <h1 className="text-3xl font-bold text-center text-primary">
          {t("landing.usefulSites.title")}
        </h1>
        <div className="container grid 2xl:grid-cols-3 xl:grid-cols-3 lg:grid-cols-3 md:grid-cols-2 sm:grid-cols-1 gap-5 mt-5 pt-5 pl-0 pr-0 md:pl-10 md:pr-10 lg:pl-20 lg:pr-20 xl:pl-20 xl:pr-20">
          {usefulSites.map((item) => (
            <a
              key={item.link}
              href={item.link}
              target="_blank"
              rel="noopener noreferrer"
            >
              <div className="group card rounded-md b-white border transition-shadow ease-in-out delay-100 hover:shadow-2xl cursor-pointer relative overflow-hidden">
                <div className="body-card p-10 flex justify-between items-center h-auto">
                  <div className="left">
                    <h1 className="text-lg font-bold">{item.name}</h1>
                  </div>
                  <div className="right">
                    <div className="w-12 h-12 bg-primary rounded-full text-white flex items-center justify-center">
                      <IoArrowForwardOutline />
                    </div>
                  </div>
                </div>
              </div>
            </a>
          ))}
        </div>
      </section>
      <footer className="text-gray-600 bg-primary">
        <div className="py-5 border-t flex justify-center items-center flex-col sm:flex px-10">
          <div className="left">
            <p className="text-white text-sm text-center">
              © Notaría 66. {t("landing.footer.AllRightsReserved")}.
            </p>
          </div>
          <div className="right flex items-center gap-1 mt-1">
            <p className="text-white text-sm">
              {t("landing.footer.imagesFrom")}
            </p>
            <Avatar
              letter="D"
              href="https://www.freepik.es/autor/drazenzigic"
            />
            <Avatar letter="FP" href="https://www.freepik.es/autor/freepik" />
            <Avatar letter="M" href="https://www.freepik.es/autor/master1305" />
            <Avatar letter="P" href="https://www.freepik.es/autor/pressfoto" />
          </div>
        </div>
      </footer>
      <div
        className="w-12 h-12 bg-primary text-white fixed bottom-0 right-0 m-5 flex justify-center items-center rounded-full z-10 cursor-pointer lg:hidden xl:hidden"
        onClick={handleOpenMenu}
      >
        <IoMenuOutline />
      </div>
      <motion.div
        className="sideMenu h-full w-56 fixed bottom-0 bg-white z-30 pt-8 flex flex-col"
        initial={{ x: "-100%" }}
        animate={{ x: isOpenMenu ? "0" : "-100%" }}
        transition={{
          type: "spring",
          duration: 1,
          delay: 0.2,
          repeatType: "mirror",
        }}
      >
        {sideMenuListData(t).map((item) => (
          <ScrollLink
            key={item.href}
            className={item.className}
            to={item.href}
            spy={true}
            smooth={true}
            offset={item.offset}
            duration={500}
          >
            {item.name}
          </ScrollLink>
        ))}
      </motion.div>
      <motion.div
        className="h-full w-full fixed bottom-0 z-20 bg-black/[.5] flex flex-col"
        initial={{ x: "-100%" }}
        animate={{ x: isOpenMenu ? "0" : "-100%" }}
        transition={{ type: "spring", duration: 1, repeatType: "mirror" }}
        onClick={handleCloseMenu}
      ></motion.div>
      <Dialog open={openImportantDialog} size="xs">
        <DialogHeader className="flex justify-center">
          ⚠️ {t("landing.navbar.importantTitle")}
        </DialogHeader>
        <DialogBody className="text-justify overflow-y-scroll max-h-80">
          {t("landing.navbar.importantVideoContent")}
          <br />
          <a href="tel:3331232322" className="text-sky-500">
            ☎️ 3331232322
          </a>
          ,
          <a href="tel:3336997481" className="text-sky-500">
            ☎️ 3336997481
          </a>
          ,
          <a href="tel:3338019889" className="text-sky-500">
            ☎️ 3338019889
          </a>
          ,<br />
          <a href="tel:3338352051" className="text-sky-500">
            ☎️ 3338352051
          </a>
          ,
          <a href="tel:3338352051" className="text-sky-500">
            ☎️ 3338351319
          </a>
          ,
          <a href="tel:3338352051" className="text-sky-500">
            ☎️ 3332685328
          </a>
          <iframe
            title="videoImportant"
            src="https://drive.google.com/file/d/142pZ2s69dq3dBKU4IOR2kEvmCM0za1D0/preview"
            width="100%"
            height="200"
            allow="autoplay"
          ></iframe>
        </DialogBody>
        <DialogFooter>
          <Button
            variant="gradient"
            color="red"
            onClick={() => handleImportantDialog(false)}
          >
            <span>{t("landing.navbar.close")}</span>
          </Button>
        </DialogFooter>
      </Dialog>
    </div>
  );
}

export default App;
