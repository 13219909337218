export const usefulSites = [{
        name: "Colegio de Notarios Jalisco",
        link: "https://notariosjalisco.com"
    },
    {
        name: "Colegio Nacional del Notariado Mexicano, A.C.",
        link: "https://www.notariadomexicano.org.mx"
    },
    {
        name: "Predial Zapopan",
        link: "https://pagos.zapopan.gob.mx/PagoEnLinea/#/busqueda-del-predio"
    },
    {
        name: "Predial Guadalajara",
        link: "https://pagoenlinea.guadalajara.gob.mx/impuestopredial/#/consulta"
    },
    {
        name: "Congreso del Estado de Jalisco",
        link: "https://www.congresojal.gob.mx/"
    },
    {
        name: "Cámara de Diputados",
        link: "https://web.diputados.gob.mx/inicio"
    }
];