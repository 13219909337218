import React from "react";

const Avatar = ({ letter, href }) => {
  return (
    <>
      <a href={href} target="_blank" rel="noreferrer">
        <div className="w-6 h-6 rounded-full flex justify-center items-center border text-white cursor-pointer text-sm border-1">
          {letter}
        </div>
      </a>
    </>
  );
};

export default Avatar;
